<template>
  <div
    class="
      min-h-screen
      w-screen
      flex
      flex-col
      content-center
      bg-login-light
      lg:bg-login-light-wide
      dark:bg-login-dark
      dark:lg:bg-login-dark-wide
      bg-center
      bg-cover
    "
  >
    <div class="items-center justify-center flex flex-grow flex-col">
      <div class="py-20 mx-auto items-center">
        <GMLogo name="vertical" class="h-32 text-profileDarkGreen dark:text-profileLightGreen" />
      </div>
      <GMButton
        size="large"
        icon-before="lock"
        class="w-56"
        primary
        @click="$msal.loginRedirect({
          scopes: ['openid', 'profile', 'api://e5f8da5a-397b-426d-a8d6-540c61993e91/user_impersonation']
        })"
      >
        {{ $t("SIGN_IN") }}
      </GMButton>
      <GMButton
        icon-before="user"
        class="mt-5"
        tertiary
        @click="$msal.loginRedirect({
          prompt: 'select_account'
        })"
      >
        {{ $t("SIGN_IN_WITH_OTHER_ACCOUNT") }}
      </GMButton>
    </div>
  </div>
</template>

<script lang="ts">

import { GMButton, GMLogo } from '@gm/components'
import {definePageMeta} from '#imports';
definePageMeta({
  layout: 'clean'
})
export default defineComponent({
  components: {
    GMButton,
    GMLogo
  }
})
</script>
<style scoped>
.bg-greeener {
  background: #10610D;
}
.bg-dark-greeener {
  background: #0C5710;
}
</style>
