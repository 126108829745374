import { default as auth7jOIwzoigtMeta } from "/home/vsts/work/1/s/pages/auth.vue?macro=true";
import { default as documentsgOvJfyjcd6Meta } from "/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/documents.vue?macro=true";
import { default as actionplans9l8gHLsdPAMeta } from "/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/edit/actionplans.vue?macro=true";
import { default as additionsZKWqaFP0FSMeta } from "/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/edit/additions.vue?macro=true";
import { default as agreemento6Iw4hRSOWMeta } from "/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/edit/agreement.vue?macro=true";
import { default as assortmentsb1KH3AJ0G6Meta } from "/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/edit/assortments.vue?macro=true";
import { default as detailsuIV95pwK7uMeta } from "/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/edit/details.vue?macro=true";
import { default as environmentcheckj8RmCvUAVDMeta } from "/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/edit/environmentcheck.vue?macro=true";
import { default as environmentreportnCmEf7P7qpMeta } from "/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/edit/environmentreport.vue?macro=true";
import { default as finaldetails3gp8RXLqKdMeta } from "/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/edit/finaldetails.vue?macro=true";
import { default as finishE9jgpphXFFMeta } from "/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/edit/finish.vue?macro=true";
import { default as indexLYDKzY13JKMeta } from "/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/edit/index.vue?macro=true";
import { default as loggingperiodkwkG1ql3bkMeta } from "/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/edit/loggingperiod.vue?macro=true";
import { default as plantingOT1gvbtNFpMeta } from "/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/edit/planting.vue?macro=true";
import { default as pricesikrVLqsJkxMeta } from "/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/edit/prices.vue?macro=true";
import { default as signagDlRmCC9wMeta } from "/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/edit/sign.vue?macro=true";
import { default as silviculturedvnk3knZwAMeta } from "/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/edit/silviculture.vue?macro=true";
import { default as summary3DouaniFlHMeta } from "/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/edit/summary.vue?macro=true";
import { default as toppleplacesUdDgN8wZKhMeta } from "/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/edit/toppleplaces.vue?macro=true";
import { default as volumeAndEntrepreneurXs0E8kuawmMeta } from "/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/edit/volumeAndEntrepreneur.vue?macro=true";
import { default as editH6nZyVSgHfMeta } from "/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/edit.vue?macro=true";
import { default as enviromentReportXNE5jQrpXoMeta } from "/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/enviromentReport.vue?macro=true";
import { default as fullsummarySyMkYY3ZXJMeta } from "/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/fullsummary.vue?macro=true";
import { default as incidentsJ2iDTiXwGBMeta } from "/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/incidents.vue?macro=true";
import { default as index6cW56GaGzqMeta } from "/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/index.vue?macro=true";
import { default as indexBy9GzFj9XWMeta } from "/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/project/index.vue?macro=true";
import { default as edit1pus5ePZtMMeta } from "/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/project/overview/firewood/[firewoodId]/edit.vue?macro=true";
import { default as indexH694sEf5LcMeta } from "/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/project/overview/index.vue?macro=true";
import { default as firewoodw04tAVFMxLMeta } from "/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/project/overview/new-order/firewood.vue?macro=true";
import { default as indexlt9ZyRFe4vMeta } from "/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/project/overview/new-order/index.vue?macro=true";
import { default as wood_45for_45own_45usemDZrNTmVGsMeta } from "/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/project/overview/new-order/wood-for-own-use.vue?macro=true";
import { default as new_45orderqsEjakJLgJMeta } from "/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/project/overview/new-order.vue?macro=true";
import { default as _91orderId_93b3RIufqVZYMeta } from "/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/project/overview/orders/[orderId].vue?macro=true";
import { default as indexqqs4IJ6bikMeta } from "/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/project/overview/orders/index.vue?macro=true";
import { default as projectx5LjIxMmyzMeta } from "/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/project.vue?macro=true";
import { default as timber6hcrdVE6rZMeta } from "/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/timber.vue?macro=true";
import { default as updatesignedykZzljL5GSMeta } from "/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/updatesigned.vue?macro=true";
import { default as _91contractId_93FoTgXBlFUBMeta } from "/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId].vue?macro=true";
import { default as indexM0gtQllIdeMeta } from "/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/index.vue?macro=true";
import { default as newContract8d8OOtIOnsMeta } from "/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/newContract.vue?macro=true";
import { default as _91propertyId_93pacs1aZk3NMeta } from "/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId].vue?macro=true";
import { default as _91agreementId_936CNHwd0ZX3Meta } from "/home/vsts/work/1/s/pages/customers/[customerId]/agreements/[agreementId].vue?macro=true";
import { default as indexiS5MA7zP1DMeta } from "/home/vsts/work/1/s/pages/customers/[customerId]/index.vue?macro=true";
import { default as _91customerId_936iRLLqYZamMeta } from "/home/vsts/work/1/s/pages/customers/[customerId].vue?macro=true";
import { default as indexegOWm4p6gtMeta } from "/home/vsts/work/1/s/pages/index.vue?macro=true";
import { default as loadingZWuVpHK7HlMeta } from "/home/vsts/work/1/s/pages/loading.vue?macro=true";
import { default as logini6Htc8i9t2Meta } from "/home/vsts/work/1/s/pages/login.vue?macro=true";
import { default as non_45invoiced_45orderszS4gyXASuaMeta } from "/home/vsts/work/1/s/pages/non-invoiced-orders.vue?macro=true";
import { default as parked_45transportScppxM0UiKMeta } from "/home/vsts/work/1/s/pages/parked-transport.vue?macro=true";
import { default as projects_45ready_452_45close18qnYDW5ACMeta } from "/home/vsts/work/1/s/pages/projects-ready-2-close.vue?macro=true";
import { default as index7CSmvzpLHrMeta } from "/home/vsts/work/1/s/pages/queue/index.vue?macro=true";
import { default as track_45damagen0iWneVCBzMeta } from "/home/vsts/work/1/s/pages/track-damage.vue?macro=true";
export default [
  {
    name: "auth",
    path: "/auth",
    component: () => import("/home/vsts/work/1/s/pages/auth.vue")
  },
  {
    name: _91customerId_936iRLLqYZamMeta?.name,
    path: "/customers/:customerId()",
    component: () => import("/home/vsts/work/1/s/pages/customers/[customerId].vue"),
    children: [
  {
    name: _91propertyId_93pacs1aZk3NMeta?.name,
    path: ":propertyId()",
    component: () => import("/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId].vue"),
    children: [
  {
    name: _91contractId_93FoTgXBlFUBMeta?.name,
    path: ":contractId()",
    component: () => import("/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId].vue"),
    children: [
  {
    name: "customers-customerId-propertyId-contractId-documents",
    path: "documents",
    component: () => import("/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/documents.vue")
  },
  {
    name: editH6nZyVSgHfMeta?.name,
    path: "edit",
    component: () => import("/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/edit.vue"),
    children: [
  {
    name: "customers-customerId-propertyId-contractId-edit-actionplans",
    path: "actionplans",
    component: () => import("/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/edit/actionplans.vue")
  },
  {
    name: "customers-customerId-propertyId-contractId-edit-additions",
    path: "additions",
    component: () => import("/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/edit/additions.vue")
  },
  {
    name: "customers-customerId-propertyId-contractId-edit-agreement",
    path: "agreement",
    component: () => import("/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/edit/agreement.vue")
  },
  {
    name: "customers-customerId-propertyId-contractId-edit-assortments",
    path: "assortments",
    component: () => import("/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/edit/assortments.vue")
  },
  {
    name: "customers-customerId-propertyId-contractId-edit-details",
    path: "details",
    component: () => import("/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/edit/details.vue")
  },
  {
    name: "customers-customerId-propertyId-contractId-edit-environmentcheck",
    path: "environmentcheck",
    component: () => import("/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/edit/environmentcheck.vue")
  },
  {
    name: "customers-customerId-propertyId-contractId-edit-environmentreport",
    path: "environmentreport",
    component: () => import("/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/edit/environmentreport.vue")
  },
  {
    name: "customers-customerId-propertyId-contractId-edit-finaldetails",
    path: "finaldetails",
    component: () => import("/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/edit/finaldetails.vue")
  },
  {
    name: "customers-customerId-propertyId-contractId-edit-finish",
    path: "finish",
    component: () => import("/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/edit/finish.vue")
  },
  {
    name: "customers-customerId-propertyId-contractId-edit",
    path: "",
    component: () => import("/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/edit/index.vue")
  },
  {
    name: "customers-customerId-propertyId-contractId-edit-loggingperiod",
    path: "loggingperiod",
    component: () => import("/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/edit/loggingperiod.vue")
  },
  {
    name: "customers-customerId-propertyId-contractId-edit-planting",
    path: "planting",
    component: () => import("/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/edit/planting.vue")
  },
  {
    name: "customers-customerId-propertyId-contractId-edit-prices",
    path: "prices",
    component: () => import("/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/edit/prices.vue")
  },
  {
    name: "customers-customerId-propertyId-contractId-edit-sign",
    path: "sign",
    component: () => import("/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/edit/sign.vue")
  },
  {
    name: "customers-customerId-propertyId-contractId-edit-silviculture",
    path: "silviculture",
    component: () => import("/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/edit/silviculture.vue")
  },
  {
    name: "customers-customerId-propertyId-contractId-edit-summary",
    path: "summary",
    component: () => import("/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/edit/summary.vue")
  },
  {
    name: "customers-customerId-propertyId-contractId-edit-toppleplaces",
    path: "toppleplaces",
    component: () => import("/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/edit/toppleplaces.vue")
  },
  {
    name: "customers-customerId-propertyId-contractId-edit-volumeAndEntrepreneur",
    path: "volumeAndEntrepreneur",
    component: () => import("/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/edit/volumeAndEntrepreneur.vue")
  }
]
  },
  {
    name: "customers-customerId-propertyId-contractId-enviromentReport",
    path: "enviromentReport",
    component: () => import("/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/enviromentReport.vue")
  },
  {
    name: "customers-customerId-propertyId-contractId-fullsummary",
    path: "fullsummary",
    component: () => import("/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/fullsummary.vue")
  },
  {
    name: "customers-customerId-propertyId-contractId-incidents",
    path: "incidents",
    component: () => import("/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/incidents.vue")
  },
  {
    name: "customers-customerId-propertyId-contractId",
    path: "",
    component: () => import("/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/index.vue")
  },
  {
    name: projectx5LjIxMmyzMeta?.name,
    path: "project",
    component: () => import("/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/project.vue"),
    children: [
  {
    name: "customers-customerId-propertyId-contractId-project",
    path: "",
    component: () => import("/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/project/index.vue")
  },
  {
    name: "customers-customerId-propertyId-contractId-project-overview-firewood-firewoodId-edit",
    path: "overview/firewood/:firewoodId()/edit",
    component: () => import("/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/project/overview/firewood/[firewoodId]/edit.vue")
  },
  {
    name: "customers-customerId-propertyId-contractId-project-overview",
    path: "overview",
    component: () => import("/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/project/overview/index.vue")
  },
  {
    name: new_45orderqsEjakJLgJMeta?.name,
    path: "overview/new-order",
    component: () => import("/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/project/overview/new-order.vue"),
    children: [
  {
    name: "customers-customerId-propertyId-contractId-project-overview-new-order-firewood",
    path: "firewood",
    component: () => import("/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/project/overview/new-order/firewood.vue")
  },
  {
    name: "customers-customerId-propertyId-contractId-project-overview-new-order",
    path: "",
    component: () => import("/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/project/overview/new-order/index.vue")
  },
  {
    name: "customers-customerId-propertyId-contractId-project-overview-new-order-wood-for-own-use",
    path: "wood-for-own-use",
    component: () => import("/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/project/overview/new-order/wood-for-own-use.vue")
  }
]
  },
  {
    name: "customers-customerId-propertyId-contractId-project-overview-orders-orderId",
    path: "overview/orders/:orderId()",
    component: () => import("/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/project/overview/orders/[orderId].vue")
  },
  {
    name: "customers-customerId-propertyId-contractId-project-overview-orders",
    path: "overview/orders",
    component: () => import("/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/project/overview/orders/index.vue")
  }
]
  },
  {
    name: "customers-customerId-propertyId-contractId-timber",
    path: "timber",
    component: () => import("/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/timber.vue")
  },
  {
    name: "customers-customerId-propertyId-contractId-updatesigned",
    path: "updatesigned",
    component: () => import("/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/updatesigned.vue")
  }
]
  },
  {
    name: "customers-customerId-propertyId",
    path: "",
    component: () => import("/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/index.vue")
  },
  {
    name: "customers-customerId-propertyId-newContract",
    path: "newContract",
    component: () => import("/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/newContract.vue")
  }
]
  },
  {
    name: "customers-customerId-agreements-agreementId",
    path: "agreements/:agreementId()",
    component: () => import("/home/vsts/work/1/s/pages/customers/[customerId]/agreements/[agreementId].vue")
  },
  {
    name: "customers-customerId",
    path: "",
    component: () => import("/home/vsts/work/1/s/pages/customers/[customerId]/index.vue")
  }
]
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/vsts/work/1/s/pages/index.vue")
  },
  {
    name: "loading",
    path: "/loading",
    meta: loadingZWuVpHK7HlMeta || {},
    component: () => import("/home/vsts/work/1/s/pages/loading.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: logini6Htc8i9t2Meta || {},
    component: () => import("/home/vsts/work/1/s/pages/login.vue")
  },
  {
    name: "non-invoiced-orders",
    path: "/non-invoiced-orders",
    component: () => import("/home/vsts/work/1/s/pages/non-invoiced-orders.vue")
  },
  {
    name: "parked-transport",
    path: "/parked-transport",
    component: () => import("/home/vsts/work/1/s/pages/parked-transport.vue")
  },
  {
    name: "projects-ready-2-close",
    path: "/projects-ready-2-close",
    component: () => import("/home/vsts/work/1/s/pages/projects-ready-2-close.vue")
  },
  {
    name: "queue",
    path: "/queue",
    component: () => import("/home/vsts/work/1/s/pages/queue/index.vue")
  },
  {
    name: "track-damage",
    path: "/track-damage",
    component: () => import("/home/vsts/work/1/s/pages/track-damage.vue")
  }
]