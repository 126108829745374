<template>
  <div class="flex-1 flex flex-col">
    <GMPropertyHeader
      v-if="currentProperty"
      :property-name="currentProperty?.Name || ''"
      :orb-text="currentProperty.IsMisRegCompleted ? $t('MISREG_PROPERTY') : ''"
      :property-path="`/customers/${$route.params.customerId}/${$route.params.propertyId}`"
      :sub-sections="[subSectionTitle]"
      class="shadow-inner"
    >
      <template #badge>
        <GMBadge
          v-if="currentProperty.IsMisRegCompleted"
          color="blue"
          icon-only
          icon="shield-check"
        >
          <span class="sr-only">
            {{ $t('MISREG_PROPERTY') }}
          </span>
        </GMBadge>
        <GMBadge v-if="currentProperty.Fsc" icon="fsc" icon-only>
          <span class="sr-only"> FSC </span>
        </GMBadge>
      </template>
    </GMPropertyHeader>

    <div class="w-full max-w-4xl mx-auto px-4 pt-6">
      <ContractHeader class="mb-8" />
      <NuxtPage />
    </div>

    <div
      class="bg-white dark:bg-gray-800 border-t border-gray-200 dark:border-gray-600 w-full fixed bottom-0"
    >
      <div class="max-w-4xl m-auto px-4">
        <div class="flex w-full">
          <div class="w-full text-left">
            <nuxt-link
              :to="
                $route.name.endsWith('overview')
                  ? `/customers/${$route.params.customerId}/${$route.params.propertyId}/${$route.params.contractId}`
                  : `/customers/${$route.params.customerId}/${$route.params.propertyId}/${$route.params.contractId}/project/overview`
              "
            >
              <GMButton
                class="!py-6 uppercase tracking-wider"
                icon-before="chevron-left"
                tertiary
              >
                {{
                  $route.name.endsWith('overview')
                    ? $t('CONTRACT')
                    : $t('PROJECT_OVERVIEW')
                }}
              </GMButton>
            </nuxt-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import ContractHeader from '@/components/Contracts/Partials/ContractHeader.vue'
import { usePropertiesStore } from '~/use/properties/store'
import { GMBadge, GMButton, GMPropertyHeader } from '@gm/components'
import { useContractsStore } from '~/use/contracts/store'
import { useHeaderStore } from '~/use/ui/header'
import { useProjectStore } from '~/use/project/store'

export default defineComponent({
  components: {
    GMPropertyHeader,
    GMBadge,
    GMButton,
    ContractHeader
  },
  setup() {
    const route = useRoute()
    const { currentProperty } = usePropertiesStore()
    const { loadContract, setCurrentContract, currentContract } =
      useContractsStore()
    const { setHeaderLink } = useHeaderStore()
    const { loadInvoiceReceivers, loadProducts } = useProjectStore()
    const { t } = useI18n()

    const subSectionTitle = ref(t('PROJECT'))
    const setSubSectionTitle = (section: string) =>
      (subSectionTitle.value = section)
    provide('setSubSectionTitle', setSubSectionTitle)

    const load = async () => {
      try {
        const contract = await loadContract(route.params.contractId as string)
        await setCurrentContract(contract)
      } catch (error) {
        console.error(error)
      }
    }

    load()
    onBeforeMount(() => {
      setHeaderLink({
        Url: `/customers/${route.params.customerId}/${route.params.propertyId}/${currentContract.value.Id || route.params.contractId}`,
        Label: 'CONTRACT'
      })
      loadInvoiceReceivers()
      loadProducts()
    })

    return {
      currentProperty,
      subSectionTitle
    }
  }
})
</script>
