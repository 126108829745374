import revive_payload_client_RGSjnun3JQ from "/home/vsts/work/1/s/node_modules/.pnpm/nuxt@3.13.2_@types+node@18.19.50_rollup@4.21.3_typescript@5.6.2_vite@5.4.6/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_1npY8x1vQN from "/home/vsts/work/1/s/node_modules/.pnpm/nuxt@3.13.2_@types+node@18.19.50_rollup@4.21.3_typescript@5.6.2_vite@5.4.6/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_LZtbFiBlOf from "/home/vsts/work/1/s/node_modules/.pnpm/nuxt@3.13.2_@types+node@18.19.50_rollup@4.21.3_typescript@5.6.2_vite@5.4.6/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_UwG4qd5PxT from "/home/vsts/work/1/s/node_modules/.pnpm/nuxt@3.13.2_@types+node@18.19.50_rollup@4.21.3_typescript@5.6.2_vite@5.4.6/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_SHzTGjP08g from "/home/vsts/work/1/s/node_modules/.pnpm/nuxt@3.13.2_@types+node@18.19.50_rollup@4.21.3_typescript@5.6.2_vite@5.4.6/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_3TQ6PQExHk from "/home/vsts/work/1/s/node_modules/.pnpm/nuxt@3.13.2_@types+node@18.19.50_rollup@4.21.3_typescript@5.6.2_vite@5.4.6/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_71agai2HCt from "/home/vsts/work/1/s/node_modules/.pnpm/nuxt@3.13.2_@types+node@18.19.50_rollup@4.21.3_typescript@5.6.2_vite@5.4.6/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/home/vsts/work/1/s/.nuxt/components.plugin.mjs";
import prefetch_client_kQ6av9yta4 from "/home/vsts/work/1/s/node_modules/.pnpm/nuxt@3.13.2_@types+node@18.19.50_rollup@4.21.3_typescript@5.6.2_vite@5.4.6/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_icons_plugin_LnrhIJeMG7 from "/home/vsts/work/1/s/.nuxt/pwa-icons-plugin.ts";
import pwa_client_I6rVcajK2b from "/home/vsts/work/1/s/node_modules/.pnpm/@vite-pwa+nuxt@0.9.1_rollup@4.21.3_vite@5.4.6_workbox-build@7.1.1_workbox-window@7.1.0/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import i18n_dLDN4McIsr from "/home/vsts/work/1/s/node_modules/.pnpm/@nuxtjs+i18n@8.0.2_rollup@4.21.3_vue@3.5.6/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import _1_application_insights_client_2isRgr4PXf from "/home/vsts/work/1/s/plugins/1.application-insights.client.ts";
import _2_auth_42b9fB0DY4 from "/home/vsts/work/1/s/plugins/2.auth.ts";
import _3_axios_5VOloTgdvi from "/home/vsts/work/1/s/plugins/3.axios.ts";
import error_handler_kEP5FliEXj from "/home/vsts/work/1/s/plugins/error-handler.ts";
import nuxt_link_import_yzIAaKi1HN from "/home/vsts/work/1/s/plugins/nuxt-link-import.ts";
import pwa_client_PLDhtd2phb from "/home/vsts/work/1/s/plugins/pwa.client.ts";
import router_watch_LdArCFqd7Z from "/home/vsts/work/1/s/plugins/router-watch.ts";
import sentry_logging_o4SBtmowK2 from "/home/vsts/work/1/s/plugins/sentry-logging.ts";
import vue_pdf_3byIq144W2 from "/home/vsts/work/1/s/plugins/vue-pdf.ts";
import vue_router_append_cjk9JpQRKU from "/home/vsts/work/1/s/plugins/vue-router-append.ts";
import vue_signature_pad_7H3GYOYmnY from "/home/vsts/work/1/s/plugins/vue-signature-pad.ts";
export default [
  revive_payload_client_RGSjnun3JQ,
  unhead_1npY8x1vQN,
  router_LZtbFiBlOf,
  payload_client_UwG4qd5PxT,
  navigation_repaint_client_SHzTGjP08g,
  check_outdated_build_client_3TQ6PQExHk,
  chunk_reload_client_71agai2HCt,
  components_plugin_KR1HBZs4kY,
  prefetch_client_kQ6av9yta4,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_I6rVcajK2b,
  i18n_dLDN4McIsr,
  _1_application_insights_client_2isRgr4PXf,
  _2_auth_42b9fB0DY4,
  _3_axios_5VOloTgdvi,
  error_handler_kEP5FliEXj,
  nuxt_link_import_yzIAaKi1HN,
  pwa_client_PLDhtd2phb,
  router_watch_LdArCFqd7Z,
  sentry_logging_o4SBtmowK2,
  vue_pdf_3byIq144W2,
  vue_router_append_cjk9JpQRKU,
  vue_signature_pad_7H3GYOYmnY
]