<template>
  <div v-if="currentCustomer && !isLoading" class="pb-4 flex-1 flex flex-col">
    <NuxtPage />
  </div>
  <div v-else class="absolute inset-0 flex items-center justify-center">
    <GMLoader size="large" />
  </div>
</template>

<script lang="ts">
import { useCustomersDb } from '~/use/indexedDb/customersDb'
import { useCustomersStore } from '~/use/customers/store'
import { useSearchPanel } from '~/use/ui/searchPanel'
import { CustomerApi } from '~/gen/openapi/sblService'
import { useAxiosClient } from '~/use/axios/client'
import type {Customer} from '~/models/customers'
import { GMLoader } from '@gm/components'

export default defineComponent({
  name: 'CustomerPage',
  components: {
    GMLoader
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const { customersDb } = useCustomersDb()
    const { axiosClient } = useAxiosClient()
    const customerApi = new CustomerApi(undefined, '', axiosClient.value)

    const { currentCustomer, setCurrentCustomer, clearCurrentCustomer } = useCustomersStore()
    const { toggleSearchPanel } = useSearchPanel()
    const isLoading = ref<Boolean>(true)
    const pageTitle = ref<string>('')

    onBeforeMount(() => {
      pageTitle.value = ''
      toggleSearchPanel(false)

      if (route.params.customerId !== currentCustomer.value?.Id) {
        clearCurrentCustomer()
      }
    })

    const load = async () => {
      if (
        typeof route.params.customerId !== 'undefined' &&
        route.params.customerId !== 'undefined'
      ) {
        try {
          const customer = await customersDb.getById(route.params.customerId as string)
          if (customer) {
            setCurrentCustomer(customer)
            isLoading.value = false
          } else {
            await customerApi.customerGetCustomerV2(route.params.customerId as string)
                .then((customerResponse) => {
                  const c = customerResponse.data[0] as unknown as Customer
                  customersDb.setById(c)
                  setCurrentCustomer(c)})
                .finally(() => (isLoading.value = false))
            return
          }
        } catch (e) {
          console.error(e)
        }
      } else {
        router.push('/')
      }
    }

    load()

    useHead({
      title: pageTitle.value
    })

    return {
      currentCustomer,
      isLoading
    }
  },
})
</script>
