<template>
  <div class="flex-1 flex flex-col relative">
    <div
      v-if="isLoading"
      class="flex w-full h-full top-20 justify-center absolute z-50"
      style="backdrop-filter: blur(1px)"
    >
      <GMLoader
        class="text-blue-gray-700 dark:text-blue-gray-300"
        size="large"
      />
    </div>
    <NuxtPage />
  </div>
</template>

<script lang="ts">

import isEmpty from 'lodash/isEmpty'
import { useAllmaJobsStore } from '~/use/allmaJobs/store'
import { useContractsStore } from '~/use/contracts/store'
import { useProjectStore } from '~/use/project/store'
import { useDraftsDb } from '~/use/indexedDb/draftsDb'
import { GMLoader } from '@gm/components'
export default defineComponent({
  name: 'ContractPage',
  components: {
    GMLoader,
  },
  setup () {
    const route = useRoute()
    const { setCurrentContract, currentContract, clearCurrentContract, getContractFromApiFallBackToIndexedDb } = useContractsStore()
    const { clearAllmaJobs } = useAllmaJobsStore()
    const { loadProject } = useProjectStore()
    const { draftsDb } = useDraftsDb()
    const isLoading = ref(true)

    watch(() => route.params.contractId, async (id) => {
      await loadProject(id as string)
      if (
        isEmpty(currentContract.value) ||
        currentContract.value.Id !== id
      ) {
        isLoading.value = true
        if (!id.includes('temp_')) {
          const c = await getContractFromApiFallBackToIndexedDb(id as string)
          if (c) {
            await setCurrentContract(c)
          } else {
            console.error('Failed to get contract:', id)
          }
        } else {
          const contract = await draftsDb.getById(id as string)
          await setCurrentContract(contract)
        }
      }
      isLoading.value = false
    }, {
      immediate: true
    })

    const clear = () => {
      if (!isEmpty(currentContract.value)) {
        clearCurrentContract()
      }
      clearAllmaJobs()
    }

    // onBeforeMount(() => {
    //   clear()
    // })

    onBeforeRouteLeave((_to, _from, next) => {
      clear()
      next()
    })
    return {
      currentContract,
      isLoading,
    }
  }
})
</script>
