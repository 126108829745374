<template>
  <Initialize v-if="showLoading" />
</template>

<script lang="ts">
import Initialize from '~/components/new/Initialize.vue'
import { useDataStore } from '~/use/data/store'

import {definePageMeta} from '#imports';
definePageMeta({
  layout: 'clean'
})
export default defineComponent({
  name: 'LoadingPage',
  components: {
    Initialize
  },
  setup () {
    const { lastUpdated } = useDataStore()
    const router = useRouter()

    const showLoading: Ref<boolean> = ref(true)

    watch(lastUpdated, () => {
      showLoading.value = false
      const currentRoute = localStorage.getItem('sbl.currentRoute') || '/'
      router.push(currentRoute)
    })

    return {
      showLoading
    }
  }
})
</script>
