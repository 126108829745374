<template>
  <div class="flex-1 flex flex-col">
    <CustomerHeader />
    <NuxtPage />
  </div>
</template>

<script lang="ts">
import CustomerHeader from '@/components/new/CustomerHeader.vue'

export default defineComponent({
  name: 'PropertyPage',
  components: {
    CustomerHeader
  }
})
</script>
