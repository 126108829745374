<template>
  <div
    class="flex-1 flex flex-col"
  >
    <GMPropertyHeader
      v-if="currentProperty"
      :property-name="currentProperty?.Name || ''"
      :orb-text="currentProperty.IsMisRegCompleted ? $t('MISREG_PROPERTY') : ''"
      :sub-sections="[$t('NEW_TYPE', { type: $t(contractLabel) })]"
      :property-path="`/customers/${$route.params.customerId}/${$route.params.propertyId}`"
      class="shadow-inner"
    >
      <template #badge>
        <GMBadge
          v-if="currentProperty.IsMisRegCompleted"
          color="blue"
          icon-only
          icon="shield-check"
        >
          <span class="sr-only">
            {{ $t('MISREG_PROPERTY') }}
          </span>
        </GMBadge>
        <GMBadge
          v-if="currentProperty.Fsc"
          icon="fsc"
          icon-only
        >
          <span class="sr-only">
            FSC
          </span>
        </GMBadge>
        <HasPowerOfAttorneysBadge :property-id="currentProperty.Id" icon-only />
      </template>

      <GMMenuButton v-if="currentStep" align="right" is-nav>
        <template #trigger="{ isVisible }">
          <GMButton
            :icon-after="isVisible ? 'chevron-up' : 'chevron-down'"
            class="uppercase tracking-wider"
            icon-only
          >
            <span class="sr-only sm:not-sr-only sm:mr-2 dark:text-blue-300">
              {{ $t(currentStep.Title) }}
            </span>
          </GMButton>
        </template>
        <GMMenuButtonOption
          v-for="step in steps"
          :key="`menuoption_${step.Page}`"
          :is-nav-active="step.Page === currentStep.Page"
          :disabled="step.Step > stepsCompletedUpTo"
          @click="goToPage(step.Page)"
        >
          {{ $t(step.Title) }}
        </GMMenuButtonOption>
      </GMMenuButton>
    </GMPropertyHeader>

    <NuxtPage
      @hidenav="showNav = false"
      @shownav="showNav = true"
      @go-next="nextStep ? goToPage(nextStep.Page) : noop()"
      @go-back="previousStep ? goToPage(previousStep.Page) : noop()"
    />

    <div
      v-if="currentStep && showNav"
      class="bg-white dark:bg-gray-800 border-t border-gray-200 dark:border-gray-600 w-full fixed bottom-0"
    >
      <div class="max-w-4xl m-auto px-4">
        <div class="flex w-full">
          <div class="w-1/3 text-left">
            <GMButton
              v-if="previousStep"
              tertiary
              icon-before="chevron-left"
              class="!py-6 uppercase tracking-wider"
              @click="goToPage(previousStep.Page)"
            >
              {{ $t(previousStep.Title) }}
            </GMButton>
          </div>
          <div class="w-1/3 text-center flex items-center justify-center dark:text-gray-300">
            <span class="text-lg">{{ currentStep.Step }} <span class="text-gray-300 dark:text-gray-500">/</span> {{ steps.length }}</span>
          </div>
          <div class="w-1/3 text-right">
            <GMButton
              v-if="nextStep"
              tertiary
              icon-after="chevron-right"
              class="!py-6 uppercase tracking-wider"
              :disabled="!valid"
              @click="goToPage(nextStep.Page)"
            >
              {{ $t(nextStep.Title) }}
            </GMButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  GMBadge,
  GMButton,
  GMMenuButton,
  GMMenuButtonOption,
  GMPropertyHeader,
  inform,
  notify
} from '@gm/components'
import type {ContractTypeStep} from '~/models/contract'
import { useDraftNotice } from '~/use/contracts/saveDraftNotice'
import { useContractsStore } from '~/use/contracts/store'
import {
  getContractStateLabel,
  getContractTypeLabel,
  getContractTypeSteps,
  isDraft
} from '~/use/contracts/util'
import { isPageValid } from '~/use/contracts/validations'
import { useCustomersStore } from '~/use/customers/store'
import { usePropertiesStore } from '~/use/properties/store'
import { useHeaderStore } from '~/use/ui/header'
import HasPowerOfAttorneysBadge from '~/components/new/customers/HasPowerOfAttorneysBadge.vue'
import { CONTRACT_STATES_SIGNATURE_REJECTED, CONTRACT_STATES_SIGNED } from '~/constants/contract'
import {useOnline} from '@vueuse/core';

export default defineComponent({
  name: 'EditContractPage',
  components: {
    GMBadge,
    GMButton,
    GMMenuButton,
    GMMenuButtonOption,
    GMPropertyHeader,
    HasPowerOfAttorneysBadge,
  },
  setup () {
    const route = useRoute()
    const router = useRouter()
    const { currentProperty } = usePropertiesStore()
    const { currentCustomer } = useCustomersStore()
    const {
      currentContract,
      saveContract,
      setCurrentContractReference
    } = useContractsStore()
    const { setHeaderLink } = useHeaderStore()
    const { t } = useI18n()
    const isOnline = useOnline()

    useHead({
      title: `${t(getContractTypeLabel(currentContract.value.Type))} ${currentContract.value?.ContractNo || (t(getContractStateLabel(currentContract.value)) as string).toLowerCase()} | ${currentProperty.value?.Name} | ${currentCustomer.value?.Name}`
    })

    const hasContractData = computed(() => JSON.stringify(currentContract.value) !== '{}')

    if (!hasContractData.value) {
      notify(t('error.EMPTY_CURRENT_CONTRACT').toString(), {
        type: 'error'
      })
      router.push(`/customers/${currentCustomer.value.Id}/${currentProperty.value.Id}`)
    }

    const {
      isShowing,
      showDraftNotice,
      discardDraft,
      saveDraft
    } = useDraftNotice()

    const showNav: Ref<boolean> = ref(true)

    const contractLabel: ComputedRef<string> = computed(() => getContractTypeLabel(currentContract.value.Type).toLowerCase())
    const steps: ComputedRef<ContractTypeStep[]> = computed(() => getContractTypeSteps(currentContract.value.Type))
    const currentPage: ComputedRef<string> = computed(() => route.name.split('-').slice(-1)[0])
    const currentStep: ComputedRef<ContractTypeStep> = computed(() => steps.value.find(x => x.Page === currentPage.value))
    const previousStep: ComputedRef<ContractTypeStep> = computed(() => steps.value.find(x => x.Step === currentStep.value?.Step - 1) || null)
    const nextStep: ComputedRef<ContractTypeStep> = computed(() => steps.value.find(x => x.Step === currentStep.value?.Step + 1) || null)
    const stepsCompletedUpTo: ComputedRef<number> = computed(() => {
      let max = null
      steps.value.forEach((step: ContractTypeStep) => {
        if (!max && !isPageValid(computed(() => step.Page), currentContract).value) {
          max = step.Step
        }
      })
      return max || steps.value.length
    })

    onBeforeMount(() => {
      setHeaderLink({
        Url: `/customers/${currentCustomer.value.Id}/${currentProperty.value.Id}/${currentContract.value.Id || route.params.contractId}`,
        Label: 'CONTRACT'
      })
      // Cant edit signed contracts
      if (typeof currentContract.value.Id !== 'undefined' && !isDraft(currentContract.value) && currentContract.value.Status >= CONTRACT_STATES_SIGNED && currentContract.value.Status !== CONTRACT_STATES_SIGNATURE_REJECTED) {
        throw createError({message: 'PAGE_NOT_ALLOWED'})
      }
    })

    const noop = () => {}

    const goToPage = (page: string): void => {
      router.push(page)
    }
    const valid = computed(() => {
      return isPageValid(currentPage, currentContract).value
    })

    onBeforeRouteLeave(async (to, _, next) => {
      // If redirect to login, assume user wants so save before being redirected back
      if (to.path.includes('/login')) {
        await saveDraft(currentContract.value)
        next()
        return
      }
      if (isOnline && !isShowing.value && hasContractData.value) {
        const result = await showDraftNotice()

        switch (result) {
          case 'cancel': {
            next(false)
            break
          }
          case 'ignore': {
            next()
            break
          }
          case 'discard': {
            await discardDraft(currentContract.value)
            if (to.name === 'customers-customerId-propertyId-contractId') {
              next({
                name: 'customers-customerId-propertyId',
                params: {
                  customerId: currentCustomer.value.Id,
                  propertyId: currentProperty.value.Id
                }
              })
              return ''
            } else {
              next()
            }
            break
          }
          case 'saveDraft': {
            await saveDraft(currentContract.value)
            next()
            break
          }
          case 'saveContract': {
            const loadingModal = inform(t('SAVING_AND_SYNCRONIZING_DRAFT') as string, {
              icon: 'document',
              loading: true
            })
            await Promise.all([saveDraft(currentContract.value), saveContract(currentContract.value)])
            setCurrentContractReference(currentContract.value)
            loadingModal && (loadingModal as any)?.remove()
            notify(t('CONTRACT_SAVED') as string, {
              icon: 'check',
              type: 'success'
            })
            next()
            break
          }
          default: {
            next()
          }
        }
      } else {
        next()
      }
    })

    return {
      currentProperty,
      currentContract,
      currentCustomer,
      contractLabel,
      getContractTypeLabel,
      getContractStateLabel,
      steps,
      isPageValid,
      currentStep,
      previousStep,
      nextStep,
      showNav,
      stepsCompletedUpTo,
      valid,
      goToPage,
      noop,
      isOnline,
    }
  },
  head: {
    title: ''
  }
})
</script>
